import React, { useEffect } from 'react';
//= Layout
import PreviewLayout from 'layouts/Preview';
//= Components
import Header from 'components/Preview/Header';
import Features from 'components/Preview/Features';
import Demos from 'components/Preview/Demos';
import InnerPages from 'components/Preview/InnerPages';
import BuyNow from 'components/Preview/BuyNow';
import Portfolio from 'components/Preview/Portfolio';
import Codei from 'components/Preview/Codei';
import BestFeatures from 'components/Preview/BestFeatures';
import Responsive from 'components/Preview/Responsive';
import AllFeatures from 'components/Preview/AllFeatures';
import Testimonials from 'components/Preview/Testimonials';
import CallToAction from 'components/Preview/CallToAction';
//= Scripts
import { fixPreviewStylesheetOrder } from 'common/fixStylesheetsOrder';
import HomeSaasTechnology from './home-saas-technology';

const LandingPreview = () => {
  useEffect(() => {
    document.body.classList.add('index-main');
    return () => document.body.classList.remove('index-main');
  }, []);

  useEffect(() => {
    fixPreviewStylesheetOrder();
  }, []);

  return (
    // <PreviewLayout>
    //   <Header />
    //   <Features />
    //   <Demos />
    //   <InnerPages />
    //   <BuyNow />
    //   <Portfolio />
    //   <Codei />
    //   <BestFeatures />
    //   <Responsive />
    //   <AllFeatures />
    //   <Testimonials />
    //   <CallToAction />
    // </PreviewLayout>
    <HomeSaasTechnology />
  );
};

export const Head = () => {
  return (
    <>

      <title>Woxox-Future CRM</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
      <link rel="stylesheet" href="/landing-preview/css/preview-style.css" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
    </>
  );
};

export default LandingPreview;
